import { default as dashboard0Xl0Pqx61tMeta } from "/home/forge/productions.strauss.smake.eu/releases/v0.2.0/pages/[hash]/dashboard.vue?macro=true";
import { default as entranceJKiMNgKDPuMeta } from "/home/forge/productions.strauss.smake.eu/releases/v0.2.0/pages/[hash]/entrance.vue?macro=true";
import { default as indexbZ2B49YTCdMeta } from "/home/forge/productions.strauss.smake.eu/releases/v0.2.0/pages/[hash]/index.vue?macro=true";
import { default as loginJR5dIBumjrMeta } from "/home/forge/productions.strauss.smake.eu/releases/v0.2.0/pages/[hash]/login.vue?macro=true";
export default [
  {
    name: "hash-dashboard",
    path: "/:hash()/dashboard",
    component: () => import("/home/forge/productions.strauss.smake.eu/releases/v0.2.0/pages/[hash]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entranceJKiMNgKDPuMeta?.name ?? "hash-entrance",
    path: "/:hash()/entrance",
    meta: entranceJKiMNgKDPuMeta || {},
    component: () => import("/home/forge/productions.strauss.smake.eu/releases/v0.2.0/pages/[hash]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "hash",
    path: "/:hash()",
    component: () => import("/home/forge/productions.strauss.smake.eu/releases/v0.2.0/pages/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: loginJR5dIBumjrMeta?.name ?? "hash-login",
    path: "/:hash()/login",
    meta: loginJR5dIBumjrMeta || {},
    component: () => import("/home/forge/productions.strauss.smake.eu/releases/v0.2.0/pages/[hash]/login.vue").then(m => m.default || m)
  }
]